import { AxiosError } from 'axios'

const CAPTCHA_API_KEY = process.env.REACT_APP_CAPTCHA_API_KEY

/**
 * Determines if a CAPTCHA is required based on the error response.
 * @param {AxiosError} error - The error object from an Axios request.
 * @returns {boolean} - Returns `true` if the error status is 405 and the 'x-amzn-waf-action' header is 'captcha', otherwise `false`.
 */
export const captchaRequired = (error: AxiosError): boolean =>
  error.response?.status === 405 && error.response?.headers['x-amzn-waf-action'] === 'captcha'

/**
 * Displays the captcha elements on the page and hides the main content.
 */
export const showCaptchaElements = (): void => {
  document.body.style.cursor = 'wait'
  document.getElementById('captchaHeader')!.style.display = 'block'
  document.getElementById('captchaModalContainer')!.style.display = 'block'
  document.getElementById('captchaModal')!.style.display = 'block'
  document.getElementById('root')!.style.display = 'none'
}

/**
 * Hides the captcha elements on the page and shows the main content.
 */
const hideCaptchaElements = (): void => {
  document.getElementById('captchaHeader')!.style.display = 'none'
  document.getElementById('captchaModalContainer')!.style.display = 'none'
  document.getElementById('captchaModal')!.style.display = 'none'
  document.getElementById('root')!.style.display = 'initial'
}

/**
 * Handles the captcha load event.
 */
export const handleCaptchaLoad = (): void => {
  document.body.style.cursor = 'default'
  document.getElementById('captchaModalContainer')?.focus()
  document.getElementById('captchaModal')?.focus()
}

/**
 * Handles the captcha success event.
 * This function should resolve the promise with the WAF token.
 */
export const handleCaptchaSuccess =
  (resolve: (value: string) => void) =>
  (wafToken: string): void => {
    hideCaptchaElements()
    resolve(wafToken)
  }

/**
 * Handles the captcha error event.
 * This function should throw an error to prevent the application from continuing.
 */
export const handleCaptchaError = (): void => {
  throw new Error('Error rendering captcha')
}

/**
 * Renders the CAPTCHA on the page.
 * @param {HTMLFormElement} captchaForm - The form element to render the CAPTCHA in.
 * @returns {Promise<string>} - Resolves with the WAF token when the CAPTCHA is successfully completed.
 * @throws {Error} - Throws an error if the CAPTCHA_API_KEY is not defined or if the form is not found.
 */
export const renderCaptcha = (captchaForm: HTMLFormElement): Promise<string> => {
  if (!CAPTCHA_API_KEY) throw new Error('CAPTCHA_API_KEY is not defined')
  return new Promise((resolve) => {
    window?.AwsWafCaptcha.renderCaptcha(captchaForm, {
      apiKey: process.env.REACT_APP_CAPTCHA_API_KEY ?? '',
      onSuccess: handleCaptchaSuccess(resolve),
      onLoad: handleCaptchaLoad,
      onError: handleCaptchaError
    })
  })
}

/**
 * Renders the captcha modal and returns a promise that resolves to a string.
 *
 * @returns {Promise<string>} A promise that resolves to a string after rendering the captcha.
 * @throws {Error} If the captcha form is not found in the DOM.
 */
export const renderCaptchaModal = async (): Promise<string> => {
  const captchaForm = document.getElementById('captchaForm') as HTMLFormElement
  showCaptchaElements()
  if (!captchaForm) throw new Error('Captcha form not found')
  return renderCaptcha(captchaForm)
}
